import styled, { css } from 'styled-components';

import PectenSVG from './components/PectenIcon';
import { pectenSizes, pectenPaddings } from './Pecten.consts';
import { IPectenProps, PectenColorStyle } from './Pecten.types';

const Pecten = styled(PectenSVG)<Required<Pick<IPectenProps, 'size' | 'padding'>> & { $colorStyle: PectenColorStyle }>`
  ${({ theme, prefixCls, $colorStyle, size, padding }) => css`
    height: ${pectenSizes[size]};
    width: ${pectenSizes[size]};
    margin: ${pectenPaddings[size][padding]};

    .${prefixCls}-border {
      fill: ${$colorStyle === 'color' ? 'white' : theme.text.onSurface.strong};
    }

    .${prefixCls}-outer-shell {
      fill: ${$colorStyle === 'color' ? '#DD1D20' : theme.text.onSurface.strong};
    }

    .${prefixCls}-inner-shell {
      fill: ${$colorStyle === 'color' ? '#FBCF09' : 'transparent'};
    }
  `}
`;

export { Pecten };
