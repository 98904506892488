import { StepperSize } from './Stepper.types';

type StepConsts = {
  [size in StepperSize]: {
    bodyGap: number;
    bodyMargin: number;
    bodyEmptyDotMargin: number;
    bodyEmptyDotInProgressMargin: number;
    verticalStepGap: number;
    textDotSize: number;
    textDotMargin: number;
    emptyDotSize: number;
    emptyDotMargin: number;
    emptyDotInProgressSize: number;
    emptyDotInProgressMargin: number;
    icon: number;
    lineMargin: number;
    descriptionBottomMargin: number;
    descriptionLeftMargin: number;
    descriptionDotLeftMargin: number;
  };
};

export const stepConsts: StepConsts = {
  small: {
    bodyGap: 4,
    bodyMargin: 6,
    bodyEmptyDotMargin: -5,
    bodyEmptyDotInProgressMargin: -3,
    verticalStepGap: 12,
    textDotSize: 32,
    textDotMargin: 2,
    emptyDotSize: 10,
    emptyDotMargin: 13,
    emptyDotInProgressSize: 14,
    emptyDotInProgressMargin: 11,
    icon: 20,
    lineMargin: 17,
    descriptionBottomMargin: 32,
    descriptionLeftMargin: 44,
    descriptionDotLeftMargin: 26,
  },
  medium: {
    bodyGap: 8,
    bodyMargin: 8,
    bodyEmptyDotMargin: -7,
    bodyEmptyDotInProgressMargin: -5,
    verticalStepGap: 16,
    textDotSize: 40,
    textDotMargin: 0,
    emptyDotSize: 10,
    emptyDotMargin: 15,
    emptyDotInProgressSize: 14,
    emptyDotInProgressMargin: 13,
    icon: 24,
    lineMargin: 19,
    descriptionBottomMargin: 44,
    descriptionLeftMargin: 52,
    descriptionDotLeftMargin: 26,
  },
  large: {
    bodyGap: 8,
    bodyMargin: 8,
    bodyEmptyDotMargin: -9,
    bodyEmptyDotInProgressMargin: -9,
    verticalStepGap: 16,
    textDotSize: 48,
    textDotMargin: -2,
    emptyDotSize: 10,
    emptyDotMargin: 17,
    emptyDotInProgressSize: 14,
    emptyDotInProgressMargin: 15,
    icon: 28,
    lineMargin: 21,
    descriptionBottomMargin: 36,
    descriptionLeftMargin: 60,
    descriptionDotLeftMargin: 26,
  },
};
