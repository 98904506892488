import { forwardRef } from 'react';

import classNames from 'classnames';

import { PROGRESS_BAR_LINEAR } from '../../../../utils/constants';
import Styled from './LinearProgressBar.styles';
import { ILinearProgressBarProps } from './LinearProgressBar.types';

const LinearProgressBar = forwardRef<HTMLDivElement, ILinearProgressBarProps>(
  ({ className, value, sentiment, state, size, label }, ref) => (
    <Styled.Container className={classNames(PROGRESS_BAR_LINEAR, className)} ref={ref}>
      <Styled.LinearBackgroundBar size={size}>
        <Styled.LinearFilledBar size={size} sentiment={sentiment || state || 'default'} value={value} />
      </Styled.LinearBackgroundBar>
      {label && <Styled.Label size={size}>{value}%</Styled.Label>}
    </Styled.Container>
  ),
);

export default LinearProgressBar;
