import styled, { css } from 'styled-components';

import { getBackgroundColorByVariant, getColorByVariant, getBorderColorByVariant } from '../../utils/colorUtils';
import Button from '../Button/Button';
import { iconSizes, tagConsts } from './Tag.consts';
import { TagIconPosition, ITagProps, TagSize } from './Tag.types';

type StyledLabelProps = {
  size: TagSize;
  iconPosition: TagIconPosition;
  $haveIcon: boolean;
};

type StylePropsRequired = 'size' | 'shape' | 'sentiment' | 'variant' | 'iconPosition' | 'icon' | 'iconOnly';

const Tag = styled.div<Required<Pick<ITagProps, StylePropsRequired>>>`
  ${({ theme, sentiment, shape, size, variant, iconPosition, icon, iconOnly }) => css`
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: ${getBackgroundColorByVariant(theme, sentiment, variant)};
    min-height: ${tagConsts.minHeight[size]};
    content: ${iconPosition};
    padding: ${iconPosition && icon && !iconOnly
      ? tagConsts.padding.containerWithIcon[iconPosition][shape][size]
      : tagConsts.padding.container[shape][size]};
    border-radius: ${tagConsts.radius[shape][size]};
    color: ${getColorByVariant(theme, sentiment, variant)};
    border: ${variant === 'outlined' && `1px solid ${getBorderColorByVariant(theme, sentiment, variant)}`};

    svg {
      width: ${iconSizes[size]};
      height: ${iconSizes[size]};

      path {
        fill: ${getColorByVariant(theme, sentiment, variant)};
      }
    }
  `}
`;

const Label = styled.span<StyledLabelProps>`
  ${({ theme, size }) => css`
    ${size === 'large' && theme.label.large.normal}
    ${size === 'medium' && theme.label.medium.normal}
    ${size === 'small' && theme.label.small.normal}
    padding: ${tagConsts.padding.label[size]};
  `};
`;

const TagButton = styled(Button)`
  padding: 0;
  margin-left: 4px;
  width: auto;
  height: auto;
  min-height: auto;
  border: none;
`;

export { Tag, Label, TagButton };
