import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { PECTEN_CLASS } from '../../utils/constants';
import * as Styled from './Pecten.styles';
import { IPectenProps } from './Pecten.types';

const Pecten = ({
  className,
  size = 'medium',
  colorStyle = 'color',
  padding = 'none',
  ...rest
}: IPectenProps): ReactElement => (
  <Styled.Pecten
    className={classNames(PECTEN_CLASS, className)}
    prefixCls={PECTEN_CLASS}
    size={size}
    $colorStyle={colorStyle}
    padding={padding}
    {...rest}
  />
);

export default Pecten;
