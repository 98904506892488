import React, { ReactElement } from 'react';

import { IStepProps } from './Step.types';
import VerticalStep from '../VerticalStep/VerticalStep';
import HorizontalStep from '../HorizontalStep/HorizontalStep';

const Step = ({ mode = 'horizontal', error, invalid, finish, isFinished, ...rest }: IStepProps): ReactElement => {
  const invalidValue = invalid || error || false;
  const isFinishedValue = isFinished || finish || false;

  if (mode === 'horizontal') {
    return <HorizontalStep mode={mode} invalid={invalidValue} isFinished={isFinishedValue} {...rest} />;
  }

  return <VerticalStep mode={mode} invalid={invalidValue} isFinished={isFinishedValue} {...rest} />;
};

export default Step;
