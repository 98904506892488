import { Theme } from '../../../providers/theme/theme';

export const getTextColor = (theme: Theme, error: boolean, waiting: boolean) => {
  if (waiting) {
    return theme.text.onSurface.subtle;
  }

  if (error) {
    return theme.system.strong.negative;
  }

  return theme.text.onSurface.strong;
};

export const getBadgeBorderColor = (theme: Theme, error: boolean, waiting: boolean, isFinished: boolean) => {
  if (error) {
    return theme.system.strong.negative;
  }

  if (waiting && !isFinished) {
    return theme.border.medium;
  }

  return theme.accent.primary.strong;
};

export const getLineBorderColor = (theme: Theme, finished: boolean, error: boolean) => {
  if (error) {
    return theme.system.strong.negative;
  }

  if (finished) {
    return theme.accent.primary.strong;
  }

  return theme.border.medium;
};

export const getDotBackgroundColor = (theme: Theme, inProgress: boolean, error: boolean, waiting: boolean) => {
  if (inProgress) {
    return theme.background.surface;
  }

  if (error) {
    return theme.system.strong.negative;
  }

  if (waiting) {
    return theme.border.medium;
  }

  return theme.accent.primary.strong;
};

export const getDotBorderColor = (theme: Theme, inProgress: boolean, error: boolean) => {
  if (error) {
    return theme.system.strong.negative;
  }

  if (inProgress) {
    return theme.accent.primary.strong;
  }

  return 'transparent';
};
