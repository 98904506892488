import React from 'react';

import Button from '../../../Button/Button';
import ChevronDown from '../../../Icon/components/ChevronDown';
import * as Styled from '../../Avatar.styles';
import { IAvatarDropdown } from './AvatarDropdown.types';
import Popover from '../../../Popover/Popover';

const AvatarDropdown = ({ dropdownOverlay, children }: IAvatarDropdown) => (
  <Popover popup={dropdownOverlay}>
    <Styled.AvatarDropdown>
      {children}
      <Button size="small" variant="transparent" icon={<ChevronDown width={16} />} iconOnly />
    </Styled.AvatarDropdown>
  </Popover>
);

export default AvatarDropdown;
