import styled, { css } from 'styled-components';

import { getLineBorderColor } from '../../utils/styleUtils';
import { Title, Description } from '../Step/Step.styles';
import { StepType } from '../../Stepper.types';
import { IStepProps } from '../Step/Step.types';
import { stepperConsts } from '../../Stepper.consts';
import { stepConsts } from '../../Step.consts';

const HorizontalTitle = styled(Title)`
  margin: 8px 0;
`;

const HorizontalDescription = styled(Description)<
  Required<Pick<IStepProps, 'alignment' | 'size'>> & { stepType: StepType }
>(
  ({ alignment, stepType, size }) => css`
    ${alignment === 'right' &&
    css`
      margin-left: ${stepType === 'dot'
        ? stepConsts[size].descriptionDotLeftMargin
        : stepConsts[size].descriptionLeftMargin}px;
    `}
    ${alignment === 'left' &&
    css`
      margin-right: ${stepType === 'dot'
        ? stepConsts[size].descriptionDotLeftMargin
        : stepConsts[size].descriptionLeftMargin};
    `}
  `,
);

const Line = styled.hr<Required<Pick<IStepProps, 'size' | 'isFinished' | 'invalid' | 'waiting'>>>`
  ${({ size, isFinished, invalid, waiting, theme }) => css`
    display: flex;
    flex-grow: 1;
    min-width: ${stepperConsts.lineMinWidth}px;
    margin-top: ${stepConsts[size].lineMargin}px;
    margin-left: 16px;
    margin-right: ${stepperConsts.gapHorizontal}px;
    border: none;
    border-top: 2px ${waiting ? 'dashed' : 'solid'} ${getLineBorderColor(theme, isFinished, invalid)};
  `}
`;

const Row = styled.div<Required<Pick<IStepProps, 'alignment'>>>`
  ${({ alignment }) => css`
    display: flex;

    ${alignment === 'left' &&
    css`
      flex-direction: row-reverse;

      ${Line} {
        margin-left: unset;
        margin-right: 16px;
      }
    `}
  `};
`;

export { Row, HorizontalTitle, HorizontalDescription, Line };
