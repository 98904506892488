import styled, { css } from 'styled-components';

import Text from '../../../Text/components/Text/Text';
import { cardConsts } from '../../Card.consts';
import { CardPadding, CardSize } from '../../Card.types';
import { CardBarType } from './CardBar.types';

type CardBarProps = {
  padding: CardPadding;
  size: CardSize;
  displayHeader?: boolean;
  border?: boolean;
  type: CardBarType;
  onlyContent: boolean;
};

const TextColumn = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 2px;
`;

const ContentColumn = styled.div<{ onlyContent: boolean }>`
  display: flex;
  flex-flow: row;
  justify-content: center;

  ${({ onlyContent }) =>
    onlyContent &&
    css`
      width: 100%;
    `}
`;

const CardBar = styled.div<CardBarProps>`
  ${({ type, size, padding, border, onlyContent, theme }) => css`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;

    ${theme.paragraph.small.normal}

    ${!onlyContent &&
    css`
      padding: ${cardConsts[size][padding].padding};

      ${TextColumn} {
        margin-right: ${cardConsts[size][padding].padding};
      }
    `}

    ${type === 'header' &&
    border &&
    css`
      border-bottom: 1px solid ${theme.border.subtle};
    `}

    ${type === 'footer' &&
    border &&
    css`
      border-top: 1px solid ${theme.border.subtle};
    `}

    ${onlyContent &&
    css`
      justify-content: flex-start;
    `}
  `}
`;

const Title = styled(Text)`
  ${({ theme }) => theme.heading[4].bold};
`;

const Description = styled(Text)`
  ${({ theme }) => css`
    ${theme.label.small.normal}
    color: ${theme.text.onSurface.subtle};
  `}
`;

export { CardBar, TextColumn, ContentColumn, Title, Description };
