import { StepperSize, StepType } from '../Stepper.types';

export const getTitleLevel = (size: StepperSize) => {
  switch (size) {
    case 'small':
      return 4;
    case 'large':
      return 2;
    default:
      return 3;
  }
};

const getSmallLineMinHeight = (type: StepType) => {
  switch (type) {
    case 'dot':
      return 48;
    case 'navigation':
      return 76;
    default:
      return 40;
  }
};

const getMediumLineMinHeight = (type: StepType) => {
  switch (type) {
    case 'dot':
      return 68;
    case 'navigation':
      return 96;
    default:
      return 56;
  }
};

const getLargeLineMinHeight = (type: StepType) => {
  switch (type) {
    case 'dot':
      return 68;
    case 'navigation':
      return 104;
    default:
      return 48;
  }
};

export const getLineMinHeight = (size: StepperSize, type: StepType) => {
  switch (size) {
    case 'small':
      return getSmallLineMinHeight(type);
    case 'large':
      return getLargeLineMinHeight(type);
    default:
      return getMediumLineMinHeight(type);
  }
};
