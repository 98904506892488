import styled, { css } from 'styled-components';

import { ProgressBarConstsColors, progressBarConsts } from '../../ProgressBarConsts';
import { ProgressBarSize, ProgressBarSentiment } from '../../ProgressBar.types';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Line = styled.div<{ size: ProgressBarSize }>`
  ${({ size }) => css`
    height: ${progressBarConsts[size].strokeWidth}px;
    border-radius: 8px;
  `}
`;

const LinearBackgroundBar = styled(Line)`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.border.subtle};
  `}
`;

const LinearFilledBar = styled(Line)<{ value: number; sentiment: ProgressBarSentiment }>`
  ${({ theme, value, sentiment }) => css`
    width: ${`${value}%`};
    border-radius: 8px;
    background: ${ProgressBarConstsColors(theme).color[sentiment]};
    transition: width 0.3s ease-out;
  `}
`;

const Label = styled.span<{ size: ProgressBarSize }>`
  ${({ size, theme }) => css`
    ${size === 'small' && theme.heading[4].normal};
    ${size === 'medium' && theme.heading[3].normal};
    margin-left: 10px;
  `}
`;

export default { Container, LinearBackgroundBar, LinearFilledBar, Label };
