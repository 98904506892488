import styled, { css } from 'styled-components';

import Button from '../Button/Button';
import { rgba2rgb } from '../../utils/colorUtils';
import { tableConsts } from './Table.consts';
import { ITableProps } from './Table.types';
import { Sentiments, DefaultSentiment } from '../../types/types';

interface ITableRowProps {
  isSelected?: boolean;
  sentiment?: Sentiments | DefaultSentiment;
}

interface ITableColumnHeaderProps {
  colSpan?: number;
}

interface ITableCellExpanderContainerProps {
  depth: number;
}

const gap = 12;

export const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${gap}px;
`;

export const TableCellExpanderContainer = styled.div<ITableCellExpanderContainerProps>(
  ({ depth }) => css`
    display: flex;
    align-items: center;
    padding-left: ${depth * 2}rem;
  `,
);

export const TableHeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${gap}px;
`;

export const TableSorter = styled(Button)``;

export const TableFilter = styled(Button)``;

const sharedCellStyles = css`
  color: ${({ theme }) => theme.text.onSurface.strong};
  box-sizing: border-box;
  overflow-wrap: break-word;
`;

export const TH = styled.th<ITableColumnHeaderProps>(
  ({ colSpan }) => css`
    ${sharedCellStyles};

    text-align: ${(colSpan ?? 0) > 1 ? 'center' : 'left'};
  `,
);

export const TD = styled.td`
  ${sharedCellStyles};
`;

export const THead = styled.thead(
  ({ theme }) => css`
    tr {
      border-bottom: 1px solid ${theme.border.medium};
    }
  `,
);

export const TBody = styled.tbody``;

export const TFoot = styled.tfoot``;

export const TR = styled.tr<ITableRowProps>(
  ({ theme, isSelected, sentiment = 'default' }) => css`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.border.subtle};
    }

    &:hover {
      background-color: ${rgba2rgb(theme.focus.subtle, theme.background.surface)};
    }

    ${isSelected &&
    css`
      background-color: ${rgba2rgb(theme.focus.subtle, theme.background.surface)};
    `}

    ${sentiment !== 'default' &&
    css`
      background-color: ${theme.system.subtle[sentiment]};
    `}
  `,
);

export const TableResizer = styled.div(
  ({ theme }) => css`
    width: 2px;
    border-radius: 1px;
    margin-left: auto;
    cursor: ew-resize;
    height: 18px;
    border-radius: 1px;
    user-select: none;
    background-color: ${theme.border.medium};
  `,
);

export const TableDrag = styled(Button)`
  cursor: drag;
`;

export const Table = styled.table<ITableProps>(
  ({ theme, size, align }) => css`
    border-collapse: collapse;
    background-color: ${theme.background.raised};

    th {
      text-align: ${align};
    }

    th,
    td {
      height: ${tableConsts[size].height};
      ${theme.paragraph.small.normal}

      ${size === 'large' &&
      css`
        ${theme.paragraph.medium.normal}
      `}

      ${size === 'xsmall' &&
      css`
        padding: 0 14px;
      `};

      ${size === 'small' &&
      css`
        padding: 3px 14px;
      `};

      ${(size === 'medium' || size === 'large') &&
      css`
        padding: 4px 14px;
      `};
    }

    th {
      ${(size === 'xsmall' || size === 'small' || size === 'medium') && theme.label.medium.bold};
      ${size === 'large' && theme.label.large.bold};
    }
  `,
);
