import { forwardRef } from 'react';

import classNames from 'classnames';

import { NOOP, RADIO_BUTTON_CLASS } from '../../utils/constants';
import * as Styled from './RadioButton.styles';
import { IRadioButtonProps } from './RadioButton.types';

const RadioButton = forwardRef<HTMLInputElement, IRadioButtonProps>(
  (
    {
      className = '',
      label = '',
      size = 'medium',
      checked,
      disabled = false,
      invalid = false,
      onChange = NOOP,
      emphasis = true,
      ...rest
    }: IRadioButtonProps,
    ref,
  ) => (
    <Styled.RadioButton
      className={classNames(RADIO_BUTTON_CLASS, className)}
      disabled={disabled}
      checked={checked}
      $emphasis={emphasis}
      size={size}
    >
      <Styled.Radio
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        type="radio"
        tabIndex={0}
        $emphasis={emphasis}
        $invalid={invalid}
        ref={ref}
        {...rest}
      />
      <Styled.FakeRadio size={size} invalid={invalid} data-testid="radio" />
      {label && <span data-testid="label">{label}</span>}
    </Styled.RadioButton>
  ),
);

export default RadioButton;
