import { forwardRef } from 'react';

import classNames from 'classnames';

import { PROGRESS_BAR_CIRCLE } from '../../../../utils/constants';
import { progressBarConsts } from '../../ProgressBarConsts';
import * as Styled from './CircleProgressBar.styles';
import { ICircleProgressBarProps } from './CircleProgressBar.types';

const CircleProgressBar = forwardRef<HTMLDivElement, ICircleProgressBarProps>(
  ({ className, value, sentiment, state, size, label }, ref) => {
    const { viewBoxSize, strokeWidth } = progressBarConsts[size];
    const radius = (viewBoxSize - strokeWidth) / 2;
    const cx = radius + strokeWidth / 2;
    const cy = cx;
    const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;

    return (
      <Styled.Container className={classNames(PROGRESS_BAR_CIRCLE, className)} ref={ref}>
        <Styled.ProgressBar size={size} viewBox={viewBox}>
          <Styled.Background r={radius} cx={cx} cy={cy} />
          <Styled.Progress r={radius} cx={cx} cy={cy} sentiment={sentiment || state || 'default'} value={value} />
        </Styled.ProgressBar>
        {label && <Styled.Label size={size}>{value}%</Styled.Label>}
      </Styled.Container>
    );
  },
);

export default CircleProgressBar;
