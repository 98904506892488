import { TagShape, TagSize } from './Tag.types';

type TagConstsProps = {
  radius: {
    [shape in TagShape]: {
      [size in TagSize]: string;
    };
  };
  minHeight: {
    [size in TagSize]: string;
  };
  padding: {
    label: {
      [size in TagSize]: string;
    };
    container: {
      [shape in TagShape]: {
        [size in TagSize]: string;
      };
    };
    containerWithIcon: {
      left: {
        [shape in TagShape]: {
          [size in TagSize]: string;
        };
      };
      right: {
        [shape in TagShape]: {
          [size in TagSize]: string;
        };
      };
    };
  };
};

export const tagConsts: TagConstsProps = {
  radius: {
    round: {
      small: '8px',
      medium: '12px',
      large: '14px',
    },
    square: {
      small: '2px',
      medium: '2px',
      large: '4px',
    },
  },
  minHeight: {
    small: '16px',
    medium: '24px',
    large: '28px',
  },
  padding: {
    label: {
      small: '0 2px',
      medium: '3px 4px',
      large: '4px',
    },
    container: {
      square: {
        small: '0 2px',
        medium: '0 4px',
        large: '0 4px',
      },
      round: {
        small: '0 4px',
        medium: '0 6px',
        large: '0 8px',
      },
    },
    containerWithIcon: {
      left: {
        square: {
          small: '0 2px',
          medium: '0 4px 0 6px',
          large: '0 4px',
        },
        round: {
          small: '0 4px',
          medium: '0 6px 0 8px',
          large: '0 8px',
        },
      },
      right: {
        square: {
          small: '0 2px',
          medium: '0 6px 0 4px',
          large: '0 4px',
        },
        round: {
          small: '0 4px',
          medium: '0 8px 0 6px',
          large: '0 8px',
        },
      },
    },
  },
};

export const iconSizes = {
  small: '14px',
  medium: '20px',
  large: '24px',
};
