import { FunctionComponent } from 'react';

import { Panel } from 'rc-collapse';
import styled, { css } from 'styled-components';

import { IAccordionPanelProps } from './AccordionPanel.types';

const AccordionPanel = styled(Panel as unknown as FunctionComponent)<IAccordionPanelProps & { $border: boolean }>`
  ${({ prefixCls, $border, theme }) => css`
    ${$border &&
    css`
      border-bottom: 1px solid ${theme.border.subtle};
    `}

    &.${prefixCls}-item-disabled {
      background-color: ${theme.background.inactive};
      border-color: ${theme.border.subtle};
      color: ${theme.text.onSurface.inactive};
      cursor: not-allowed;

      .${prefixCls}-header svg path {
        fill: ${theme.text.onSurface.inactive};
      }
    }

    .${prefixCls}-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .${prefixCls}-extra {
        display: flex;
        align-items: center;
        justify-self: flex-end;
        margin-left: auto;
      }

      &-collapsible-only {
        cursor: default;

        .${prefixCls}-header-text {
          cursor: pointer;
        }
      }
    }

    .${prefixCls}-content-active {
      opacity: 1;
      height: auto;
    }

    .${prefixCls}-content-inactive {
      opacity: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  `}
`;

export { AccordionPanel };
