import styled, { css } from 'styled-components';

import { getLineBorderColor } from '../../utils/styleUtils';
import { stepConsts } from '../../Step.consts';
import { IStepProps } from '../Step/Step.types';
import { stepperConsts } from '../../Stepper.consts';
import { getLineMinHeight } from '../../utils/stepUtils';
import { Description } from '../Step/Step.styles';

const Box = styled.div<Required<Pick<IStepProps, 'type'>>>`
  ${({ type }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${type === 'dot' &&
    css`
      min-width: 14px;
    `}
  `}
`;

const Body = styled.div<Required<Pick<IStepProps, 'size' | 'alignment' | 'type' | 'inProgress'>>>`
  ${({ size, alignment, type, inProgress }) => css`
    display: flex;
    flex-direction: column;
    gap: ${stepConsts[size].bodyGap}px;
    text-align: start;

    ${type === 'base'
      ? css`
          margin-top: ${stepConsts[size].bodyMargin}px;
        `
      : css`
          margin-top: ${stepConsts[size][inProgress ? 'bodyEmptyDotInProgressMargin' : 'bodyEmptyDotMargin']}px;
        `}

    ${alignment === 'left' &&
    css`
      text-align: end;
    `}
  `}
`;

const VerticalDescription = styled(Description)<Required<Pick<IStepProps, 'size'>>>`
  ${({ size }) =>
    css`
      margin-bottom: ${stepConsts[size].descriptionBottomMargin}px;
    `}
`;

const Line = styled.hr<Required<Pick<IStepProps, 'size' | 'type' | 'isFinished' | 'invalid' | 'waiting'>>>`
  ${({ size, type, isFinished, invalid, waiting, theme }) => css`
    display: flex;
    flex-grow: 1;
    min-height: ${getLineMinHeight(size, type)}px;
    margin: ${stepperConsts.gapVertical}px 0;
    border: none;
    border-left: 2px ${waiting ? 'dashed' : 'solid'} ${getLineBorderColor(theme, isFinished, invalid)};
  `}
`;

export { Box, Body, VerticalDescription, Line };
