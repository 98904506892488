import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { LABEL_CLASS } from '../../../../utils/constants';
import * as Styled from './Label.styles';
import { ILabelProps } from './Label.types';

const Label = forwardRef<HTMLSpanElement, ILabelProps>(
  (
    { className = '', size = 'medium', type = 'span', color = 'normal', gutter = false, bold = true, ...rest },
    ref,
  ): ReactElement => (
    <Styled.Label
      className={classNames(LABEL_CLASS, className)}
      as={type}
      size={size}
      $color={color}
      gutter={gutter}
      bold={bold}
      ref={ref}
      {...rest}
    />
  ),
);

export default Label;
