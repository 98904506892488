import { Theme } from '../../providers/theme/theme';
import { ProgressBarSize, ProgressBarSentiment } from './ProgressBar.types';

type ProgressBarConstsColorsType = {
  color: {
    [sentiment in ProgressBarSentiment]: string;
  };
};

type ProgressBarConstsType = {
  [size in ProgressBarSize]: {
    strokeWidth: number;
    viewBoxSize: number;
  };
};

export const ProgressBarConstsColors = (theme: Theme): ProgressBarConstsColorsType => ({
  color: {
    default: theme.accent.primary.strong,
    positive: theme.system.strong.positive,
    negative: theme.system.strong.negative,
  },
});

export const progressBarConsts: ProgressBarConstsType = {
  large: { strokeWidth: 8, viewBoxSize: 128 },
  medium: { strokeWidth: 8, viewBoxSize: 96 },
  small: { strokeWidth: 4, viewBoxSize: 64 },
};
