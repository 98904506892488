import React, { ReactElement } from 'react';

import * as Styled from './StepWrapper.styles';
import { IStepWrapperProps } from './StepWrapper.types';

const StepWrapper = ({
  size = 'medium',
  mode = 'horizontal',
  alignment = 'right',
  type,
  last,
  children,
  onClick,
  ...rest
}: IStepWrapperProps): ReactElement => (
  <Styled.StepWrapper
    size={size}
    mode={mode}
    alignment={alignment}
    last={!!last}
    isBaseType={type === 'base'}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Styled.StepWrapper>
);

export default StepWrapper;
