import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { PARAGRAPH_CLASS } from '../../../../utils/constants';
import * as Styled from './Text.styles';
import { ITextProps } from './Text.types';

const Text = forwardRef<HTMLParagraphElement, ITextProps>(
  ({ className = '', size = 'medium', type = 'p', gutter, bold, color = 'normal', ...rest }, ref): ReactElement => (
    <Styled.Text
      className={classNames(PARAGRAPH_CLASS, className)}
      size={size}
      as={type}
      gutter={gutter}
      bold={bold}
      $color={color}
      ref={ref}
      {...rest}
    />
  ),
);

export default Text;
