import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { CARD_CLASS } from '../../utils/constants';
import * as Styled from './Card.styles';
import { ICardProps } from './Card.types';
import CardBar from './components/CardBar/CardBar';

const Card = forwardRef<HTMLDivElement, ICardProps>(
  (
    {
      className = '',
      header,
      padding = 'tight',
      size = 'medium',
      bodyPadding = true,
      children,
      borders,
      footer,
      link,
      ...rest
    },
    ref,
  ): ReactElement => (
    <Styled.Card as={link ? 'a' : 'div'} className={classNames(CARD_CLASS, className)} {...link} ref={ref} {...rest}>
      {header && <CardBar size={size} padding={padding} bar={header} border={borders} type="header" />}
      <Styled.CardBody size={size} padding={padding} bodyPadding={bodyPadding}>
        {children}
      </Styled.CardBody>
      {footer && <CardBar size={size} padding={padding} bar={footer} border={borders} type="footer" />}
    </Styled.Card>
  ),
);

export default Card;
