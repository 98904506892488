import styled, { css } from 'styled-components';

import { Title } from '../Step/Step.styles';
import { stepConsts } from '../../Step.consts';
import { IStepWrapperProps } from './StepWrapper.types';

type StepWrapperProps = Required<Pick<IStepWrapperProps, 'mode' | 'size' | 'alignment' | 'last'>> & {
  isBaseType: boolean;
  $width?: number;
};

const StepWrapper = styled.div<StepWrapperProps>`
  ${({ size, mode, alignment, onClick, isBaseType, $width }) => css`
    display: flex;
    padding-right: ${isBaseType ? '16px' : '12px'};

    ${mode === 'horizontal' &&
    css`
      flex-direction: column;
      padding: 8px 0 16px 0;

      ${$width
        ? css`
            flex: 1 0 auto;
            width: ${$width}px;
          `
        : css`
            flex-basis: 100%;
          `}
    `}

    ${mode === 'vertical' &&
    css`
      flex-direction: row;
      gap: ${stepConsts[size].verticalStepGap}px;
      padding: unset;
      ${alignment === 'left' &&
      css`
        flex-direction: row-reverse;
      `}
    `}
    
    ${onClick &&
    css`
      &:hover {
        cursor: pointer;
        ${Title} {
          text-decoration: underline;
        }
      }
    `}
  `}
`;

export { StepWrapper };
