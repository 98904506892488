import React, {
  ChangeEvent,
  ForwardedRef,
  MouseEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { InputWrapper } from '../../styles/InputStyles';
import { TEXT_INPUT_CLASS } from '../../utils/constants';
import { Cross } from '../Icon/components';
import * as Styled from './TextInput.styles';
import { ITextInputProps } from './TextInput.types';

const TextInput = forwardRef(
  (
    {
      className = '',
      size = 'medium',
      invalid = false,
      disabled = false,
      clearable = false,
      wrapperProps,
      prefix,
      suffix,
      onChange,
      onClear,
      value,
      ...rest
    }: ITextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const controlled = value !== undefined;
    const [showClear, setShowClear] = useState(controlled && value !== '' && clearable);
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    const onClearClick = (mouseEvent: MouseEvent<HTMLButtonElement>) => {
      if (innerRef.current && !controlled) {
        innerRef.current.value = '';
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
        nativeInputValueSetter?.call(innerRef.current, '');
        const event = new Event('input', { bubbles: true });
        innerRef.current.dispatchEvent(event);
        setShowClear(false);
      }
      if (onClear) onClear(mouseEvent);
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event);
      if (clearable && !disabled) {
        if (innerRef.current?.value === '' && showClear) {
          setShowClear(false);
        } else if (!showClear) {
          setShowClear(true);
        }
      }
    };

    useEffect(() => {
      if (value === '' && showClear) {
        setShowClear(false);
      }
    }, [value, showClear]);

    return (
      <InputWrapper
        className={classNames(TEXT_INPUT_CLASS, className)}
        disabled={disabled}
        invalid={invalid}
        {...wrapperProps}
      >
        {prefix && (
          <Styled.Affix size={size} transparent={prefix.transparent} disabled={disabled}>
            {prefix.node}
          </Styled.Affix>
        )}
        <Styled.TextInput
          ref={innerRef}
          disabled={disabled}
          inputSize={size}
          onChange={handleOnChange}
          value={value}
          {...rest}
        />
        {showClear && (
          <Styled.ClearButton size={size} variant="transparent" icon={<Cross />} iconOnly onClick={onClearClick} />
        )}
        {suffix && (
          <Styled.Affix size={size} transparent={suffix.transparent} disabled={disabled}>
            {suffix.node}
          </Styled.Affix>
        )}
      </InputWrapper>
    );
  },
);

export default TextInput;
