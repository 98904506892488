import { ReactElement } from 'react';

import Button from '../../../Button/Button';
import { FlexboxJustifyContent } from '../../../Flexbox/Flexbox.types';
import Styled from './ActionBar.styles';
import { IActionBarProps } from './ActionBar.types';

const ALIGNMENT = {
  left: 'start',
  center: 'center',
  fill: null,
  right: 'end',
};

const ActionBar = ({
  size,
  padding,
  actions,
  actionBarAlignment = 'right',
  footerDirection,
}: IActionBarProps): ReactElement => (
  <>
    {actions?.length && (
      <Styled.Container
        gap="12px"
        justifyContent={ALIGNMENT[actionBarAlignment] as FlexboxJustifyContent}
        flexDirection={footerDirection}
        actionBarAlignment={actionBarAlignment}
        size={size}
        padding={padding}
      >
        {actions.map(({ label, action, props }) => (
          <Button key={label} size={size} onClick={action} {...props}>
            {label}
          </Button>
        ))}
      </Styled.Container>
    )}
  </>
);

export default ActionBar;
