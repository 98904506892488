import React, { ReactElement } from 'react';

import StepDot from '../StepDot/StepDot';
import StepWrapper from '../StepWrapper/StepWrapper';
import * as Styled from './VerticalStep.styles';
import { Title } from '../Step/Step.styles';
import { IStepProps } from '../Step/Step.types';
import { getTitleLevel } from '../../utils/stepUtils';

const VerticalStep = ({
  mode = 'horizontal',
  size = 'medium',
  alignment = 'right',
  type = 'base',
  order,
  title,
  description,
  inProgress = false,
  waiting = false,
  invalid = false,
  isFinished = false,
  last = false,
  width = 340,
  ...rest
}: IStepProps): ReactElement => (
  <StepWrapper
    mode={mode}
    alignment={alignment}
    type={type}
    last={last}
    aria-current={inProgress ? 'step' : undefined}
    $width={width}
    {...rest}
  >
    <Styled.Box type={type}>
      <StepDot
        size={size}
        mode={mode}
        alignment={alignment}
        type={type}
        inProgress={inProgress}
        order={order}
        waiting={waiting}
        invalid={invalid}
        isFinished={isFinished}
      />
      {!last && <Styled.Line size={size} type={type} isFinished={isFinished} invalid={invalid} waiting={waiting} />}
    </Styled.Box>
    <Styled.Body size={size} alignment={alignment} type={type} inProgress={inProgress}>
      <Title level={getTitleLevel(size)} waiting={waiting} invalid={invalid}>
        {title}
      </Title>
      <Styled.VerticalDescription size={size} stepType={type}>
        {description}
      </Styled.VerticalDescription>
    </Styled.Body>
  </StepWrapper>
);

export default VerticalStep;
