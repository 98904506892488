import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { HEADING_CLASS } from '../../../../utils/constants';
import * as Styled from './Heading.styles';
import { IHeadingProps } from './Heading.types';

const Heading = forwardRef<HTMLHeadingElement, IHeadingProps>(
  ({ className = '', type = 'h1', level, gutter, bold, color = 'normal', ...rest }, ref): ReactElement => (
    <Styled.Heading
      className={classNames(HEADING_CLASS, className)}
      as={type}
      level={level}
      gutter={gutter}
      bold={bold}
      $color={color}
      ref={ref}
      {...rest}
    />
  ),
);

export default Heading;
