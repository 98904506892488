import styled, { css } from 'styled-components';

import { IStepperProps } from './Stepper.types';

const Stepper = styled.div<Pick<IStepperProps, 'mode' | 'alignment'>>`
  ${({ mode, alignment }) => css`
    display: flex;

    ${mode === 'horizontal' &&
    css`
      width: 100%;
      flex-direction: ${alignment === 'right' ? 'row' : 'row-reverse'};
    `}

    ${mode === 'vertical' &&
    css`
      height: 100%;
      flex-direction: column;
    `}
  `}
`;

export { Stepper };
