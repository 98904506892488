import styled, { css } from 'styled-components';

import { getBadgeBorderColor, getDotBackgroundColor, getDotBorderColor } from '../../utils/styleUtils';
import { stepConsts } from '../../Step.consts';
import { IStepDotProps } from './StepDot.types';

const StepDot = styled.div<IStepDotProps>`
  ${({ size, mode, alignment, type, inProgress, isFinished, invalid, waiting, theme }) => css`
    ${type !== 'dot' &&
    css`
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: ${stepConsts[size].textDotSize}px;
      height: ${stepConsts[size].textDotSize}px;
      margin-top: ${stepConsts[size].textDotMargin}px;
      background-color: ${theme.background.surface};
      border: ${inProgress ? '2px' : '1px'} solid ${getBadgeBorderColor(theme, invalid, waiting, isFinished)};
      border-radius: 50%;
      color: ${waiting ? theme.text.onSurface.subtle : theme.text.onSurface.strong};

      ${mode === 'horizontal' &&
      css`
        margin-right: 12px;
        margin-left: unset;

        ${alignment === 'left' &&
        css`
          margin-right: unset;
          margin-left: 12px;
        `}
      `}

      svg {
        height: ${stepConsts[size].icon}px;
        width: ${stepConsts[size].icon}px;

        path {
          fill: ${invalid ? theme.system.strong.negative : theme.text.onSurface.strong};
        }
      }
    `}

    ${type === 'dot' &&
    css`
      width: ${stepConsts[size][inProgress ? 'emptyDotInProgressSize' : 'emptyDotSize']}px;
      min-width: ${stepConsts[size][inProgress ? 'emptyDotInProgressSize' : 'emptyDotSize']}px;
      height: ${stepConsts[size][inProgress ? 'emptyDotInProgressSize' : 'emptyDotSize']}px;
      margin-top: ${stepConsts[size][inProgress ? 'emptyDotInProgressMargin' : 'emptyDotMargin']}px;
      border-radius: 50%;
      border: 2px solid ${getDotBorderColor(theme, inProgress, invalid)};
      background-color: ${getDotBackgroundColor(theme, inProgress, invalid, waiting)};

      ${mode === 'horizontal' &&
      css`
        margin-right: ${inProgress ? '12px' : '16px'};
      `}

      > * {
        display: none;
      }
    `}
  `}
`;

export { StepDot };
