import styled, { css } from 'styled-components';

import { getColorBySentiment } from '../../utils/colorUtils';
import Button from '../Button/Button';
import { IAlertProps } from './Alert.types';

const Alert = styled.div<Required<Pick<IAlertProps, 'sentiment' | 'size' | 'float'>>>`
  ${({ sentiment, size, theme }) => css`
    ${size === 'large' ? theme.label.medium.normal : theme.label.small.normal};

    background-color: ${theme.background.raised};
    color: ${theme.text.onSurface.strong};
    border: 1px solid ${getColorBySentiment(sentiment, theme)};
  `}

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 2px;
  min-height: 48px;
  width: 100%;

  ${({ float, sentiment, theme }) =>
    float &&
    css`
      box-shadow: ${theme.elevation.level1};
      border: 0;
      border-left: 4px solid ${getColorBySentiment(sentiment, theme)};
    `}
`;

const Title = styled.span<Required<Pick<IAlertProps, 'size'>>>`
  ${({ theme, size }) => css`
    ${size === 'large' ? theme.heading[4].bold : theme.heading[5].bold};
  `}
`;

const AlertContentContainer = styled.div<Required<Pick<IAlertProps, 'buttonsPosition'>>>`
  ${({ buttonsPosition }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: ${buttonsPosition === 'bottom' ? 'column' : 'row'};
  `}
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20px;
  row-gap: 8px;
`;

const ButtonsContainerSide = css`
  margin: 0 8px 0 8px;
  height: 20px;
  flex-grow: 1;
  justify-content: flex-end;
`;

const ButtonsContainer = styled.div<Required<Pick<IAlertProps, 'buttonsPosition'>>>`
  margin-top: 8px;
  display: flex;
  gap: 12px;
  height: fit-content;
  align-items: center;

  ${({ buttonsPosition }) => buttonsPosition === 'side' && ButtonsContainerSide};
`;

const IconContainer = styled.div<Required<Pick<IAlertProps, 'sentiment'>>>`
  display: flex;
  height: 100%;

  svg {
    margin-right: 16px;
    flex-shrink: 0;

    width: 20px;
    height: 20px;

    path {
      fill: ${({ sentiment, theme }) => getColorBySentiment(sentiment, theme)};
    }
  }
`;

const DismissContainer = styled.div`
  margin-left: 4px;
  height: 20px;
  width: 20px;
  min-height: 20px;
  position: relative;
`;

const DismissButton = styled(Button)`
  margin: -25%;
`;

export {
  Alert,
  AlertContentContainer,
  AlertContent,
  Title,
  ButtonsContainer,
  IconContainer,
  DismissContainer,
  DismissButton,
};
