import { ReactElement, useEffect, useState } from 'react';

import classNames from 'classnames';

import { FORM_FIELD_CLASS } from '../../utils/constants';
import Checkbox from '../Checkbox/Checkbox';
import Flexbox from '../Flexbox/Flexbox';
import RadioButton from '../RadioButton/RadioButton';
import Label from './components/Label/Label';
import * as Styled from './FormField.styles';
import { IFormFieldProps } from './FormField.types';

const FormField = ({
  id,
  className,
  size = 'medium',
  label,
  description,
  children,
  icon,
  iconPosition = 'left',
  mandatory = false,
  topHelper,
  bottomLeftHelper,
  bottomRightHelper,
  ...rest
}: IFormFieldProps): ReactElement => {
  const [hasCheckbox, setHasCheckbox] = useState(false);

  const labelProps = {
    ...(id && { id }),
  };

  useEffect(() => {
    const fetchModule = async () => {
      const { deepFind } = await import('react-children-utilities');

      const node = deepFind(
        children,
        (child) => (child as ReactElement)?.type === Checkbox || (child as ReactElement)?.type === RadioButton,
      );

      setHasCheckbox(node !== undefined);
    };

    fetchModule();
  }, [children]);

  const wrapper = (
    <>
      <Label
        size={size}
        icon={icon}
        iconPosition={iconPosition}
        label={label}
        description={description}
        mandatory={mandatory}
        helper={topHelper}
        {...labelProps}
      />
      {children}
    </>
  );

  return (
    <Styled.FormField className={classNames(FORM_FIELD_CLASS, className)} {...rest}>
      {hasCheckbox ? <fieldset>{wrapper}</fieldset> : wrapper}
      <Flexbox justifyContent="space-between">
        {bottomLeftHelper && (
          <Styled.Message size={size} sentiment={bottomLeftHelper.sentiment || bottomLeftHelper.state}>
            {bottomLeftHelper.content}
          </Styled.Message>
        )}
        {bottomRightHelper && (
          <Styled.Message size={size} sentiment={bottomRightHelper.sentiment || bottomRightHelper.state}>
            {bottomRightHelper.content}
          </Styled.Message>
        )}
      </Flexbox>
    </Styled.FormField>
  );
};

export default FormField;
