import React, { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { NOOP, TAG_CLASS } from '../../utils/constants';
import { Cross } from '../Icon/components';
import * as Styled from './Tag.styles';
import { ITagProps } from './Tag.types';

const Tag = forwardRef<HTMLDivElement, ITagProps>(
  (
    {
      children,
      className,
      size = 'medium',
      label,
      shape = 'square',
      variant,
      type,
      sentiment,
      state,
      dismissible = false,
      dismissable = false,
      dismissButtonTitle = 'Dismiss',
      onDismissClick = NOOP,
      icon,
      iconPosition = 'left',
      iconOnly = false,
      ...rest
    },
    ref,
  ): ReactElement => (
    <Styled.Tag
      className={classNames(TAG_CLASS, className)}
      shape={shape}
      variant={variant || type || 'filled'}
      size={size}
      sentiment={sentiment || state || 'neutral'}
      icon={!!icon}
      iconOnly={iconOnly}
      iconPosition={iconPosition}
      ref={ref}
      {...rest}
    >
      {iconPosition === 'left' ? icon : null}
      {!iconOnly && (
        <Styled.Label size={size} $haveIcon={!!icon} iconPosition={iconPosition}>
          {children || label}
        </Styled.Label>
      )}
      {iconPosition === 'right' ? icon : null}
      {(dismissible || dismissable) && (
        <Styled.TagButton
          title={dismissButtonTitle}
          size={size}
          iconOnly
          icon={<Cross />}
          onClick={onDismissClick}
          variant="transparent"
        />
      )}
    </Styled.Tag>
  ),
);

export default Tag;
