import styled, { css } from 'styled-components';

import { isTouchDevice } from '../../utils/devices';
import { CheckboxSizes } from './Checkbox.types';

type CheckboxConstsType = {
  [size in CheckboxSizes]: {
    width: number;
    height: number;
    textLeftMargin: number;
  };
};

type CheckboxWrapperProps = {
  disabled?: boolean;
  checked?: boolean;
  $invalid?: boolean;
  $emphasis?: boolean;
};

type LabelProps = {
  size: CheckboxSizes;
  disabled?: boolean;
};

type CheckboxProps = {
  indeterminate: boolean;
  $invalid?: boolean;
  $emphasis?: boolean;
};

type FakeCheckboxProps = CheckboxProps & {
  size: CheckboxSizes;
  checked?: boolean;
  disabled?: boolean;
  $invalid?: boolean;
  $emphasis?: boolean;
};

const checkboxConsts: CheckboxConstsType = {
  small: {
    width: 16,
    height: 16,
    textLeftMargin: 8,
  },
  medium: {
    width: 24,
    height: 24,
    textLeftMargin: 10,
  },
  large: {
    width: 28,
    height: 28,
    textLeftMargin: 12,
  },
};

const FakeCheckbox = styled.div<FakeCheckboxProps>`
  ${({ checked, disabled, size, theme, indeterminate, $invalid, $emphasis }) => css`
    box-sizing: border-box;
    position: relative;
    padding: 0;
    border: ${checked && disabled ? 'none' : `2px solid ${theme.border.medium}`};
    border-radius: 2px;
    transition: all 150ms;
    pointer-events: ${disabled ? 'none' : ''};
    background: ${theme.background.surface};

    ${$invalid &&
    css`
      border-color: ${theme.system.strong.negative};
    `}

    ${!$emphasis &&
    css`
      border-color: ${theme.text.onPrimary.strong};
    `}

    ${size &&
    css`
      width: ${checkboxConsts[size].width}px;
      height: ${checkboxConsts[size].height}px;
    `}

    svg {
      ${size &&
      css`
        width: ${checkboxConsts[size].width}px;
        height: ${checkboxConsts[size].height}px;
      `}

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${checked &&
      $emphasis &&
      css`
        path {
          fill: ${!indeterminate ? theme.text.onFocus.strong : theme.text.onSurface.strong};
        }
      `}

      ${checked &&
      !$emphasis &&
      css`
        path {
          fill: ${!indeterminate ? theme.text.onFocus.strong : theme.text.onSurface.strong};
        }
      `}

      ${!checked &&
      css`
        path {
          fill: ${!indeterminate ? theme.background.surface : theme.text.onSurface.strong};
        }
      `}
    }
  `}
`;

const Checkbox = styled.input<CheckboxProps>`
  position: absolute;
  left: -9999px;

  ${({ indeterminate, $invalid, $emphasis, theme }) => css`
    &:focus ~ ${FakeCheckbox} {
      box-shadow: 0 0 0 1px ${theme.background.surface}, 0 0 0 3px ${theme.focus.medium};
    }

    &:checked ~ ${FakeCheckbox} {
      background-color: ${!indeterminate ? theme.focus.strong : theme.background.surface};
      border-color: ${theme.focus.strong};

      ${!$emphasis &&
      css`
        background-color: ${theme.text.onSurface.strong};
        border-color: ${theme.text.onSurface.strong};
      `}

      ${$invalid &&
      css`
        background-color: ${theme.system.strong.negative};
        border-color: ${theme.system.strong.negative};
      `}
    }

    &:disabled ~ ${FakeCheckbox} {
      background-color: ${theme.background.inactive};
      border-color: ${theme.border.subtle};

      svg path {
        fill: transparent;
      }
    }

    &:active:not(:disabled) ~ ${FakeCheckbox} {
      border-color: ${$emphasis ? theme.focus.strong : theme.text.onSurface.strong};

      svg {
        margin: auto;

        path {
          fill: ${theme.border.medium};
        }
      }
    }

    &:disabled:checked ~ ${FakeCheckbox} {
      background-color: ${theme.focus.medium};
      border-color: ${theme.focus.medium};

      svg path {
        fill: ${theme.background.surface};
      }
    }
  `}
`;

const CheckboxWrapper = styled.label<CheckboxWrapperProps>`
  ${({ checked, disabled, $emphasis, theme }) => css`
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${disabled ? 'default' : 'pointer'};

    &:hover {
      svg {
        path {
          ${!isTouchDevice() &&
          css`
            fill: ${checked && !disabled ? theme.text.onFocus.strong : theme.text.onSurface.subtle};

            ${!$emphasis &&
            css`
              fill: ${theme.text.onSurface.subtle};
            `}
          `}

          ${checked &&
          !disabled &&
          css`
            opacity: 50%;
          `};
        }
      }
    }
  `}
`;

const Label = styled.span<LabelProps>`
  ${({ disabled, size, theme }) => css`
    ${size === 'small' && theme.label.small.bold};
    ${size === 'medium' && theme.label.medium.bold};
    ${size === 'large' && theme.label.large.bold};

    color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.strong};
    margin-left: ${checkboxConsts[size].textLeftMargin}px;
  `}

  display: inline-block;
  vertical-align: middle;
`;

export { CheckboxWrapper, Checkbox, FakeCheckbox, Label };
