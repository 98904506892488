export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';
export const RECHARGE_LIGHT_THEME = 'rechargeLight';
export const RECHARGE_DARK_THEME = 'rechargeDark';

export const Z_INDEX = {
  ELEVATED: 10,
  DATE_PICKER: 1000,
  MENU: 1030,
  MODAL: 1050,
  NAVBAR: 1030,
  DRAWER: 1040,
  SELECT: 1020,
  TOOLTIP: 1070,
  POPOVER: 1010,
  ARROW: 1090,
};

export const TRIGGER_OVERFLOW = {
  adjustX: 0,
  adjustY: 0,
};

export const NOOP = (): null => null;

export const ACCORDION_CLASS = 'shell-accordion';
export const ACCORDION_PANEL_CLASS = 'shell-accordion-panel';
export const ALERT_CLASS = 'shell-alert';
export const AVATAR_CLASS = 'shell-avatar';
export const ARROW_KEYPAD_CLASS = 'shell-arrow-keypad';
export const BADGE_CLASS = 'shell-badge';
export const BREADCRUMB_CLASS = 'shell-breadcrumb';
export const BUTTON_CLASS = 'shell-button';
export const BUTTON_GROUP_CLASS = 'shell-button-group';
export const CARD_CLASS = 'shell-card';
export const CHECKBOX_CLASS = 'shell-checkbox';
export const DATE_PICKER_CLASS = 'shell-date-picker';
export const DIVIDER_CLASS = 'shell-divider';
export const DRAWER_CLASS = 'shell-drawer';
export const DROPDOWN_CLASS = 'shell-dropdown';
export const DROPDOWN_CONTAINER_CLASS = `${DROPDOWN_CLASS}-container`;
export const DROPDOWN_CONTAINER_OVERLAY_CLASS = `${DROPDOWN_CONTAINER_CLASS}-overlay`;
export const EMPTY_STATE_CLASS = 'shell-empty-state';
export const FORM_FIELD_CLASS = 'shell-form-field';
export const ICON_CLASS = 'shell-icon';
export const LOADING_CLASS = 'shell-loading';
export const MENU_CLASS = 'shell-menu';
export const MODAL_CLASS = 'shell-modal';
export const MODAL_CONTAINER_CLASS = `${MODAL_CLASS}-container`;
export const NAV_BAR_CLASS = 'shell-nav-bar';
export const PAGINATION_CLASS = 'shell-pagination';
export const PECTEN_CLASS = 'shell-pecten';
export const POPCONFIRM_CLASS = 'shell-popconfirm';
export const POPOVER_CLASS = 'shell-popover';
export const POPOVER_OVERLAY_CLASS = 'shell-popover-overlay';
export const PROGRESS_BAR_LINEAR = 'shell-linear-progress-bar';
export const PROGRESS_BAR_CIRCLE = 'shell-circle-progress-bar';
export const RADIO_BUTTON_CLASS = 'shell-radio-button';
export const SELECT_CLASS = 'shell-select';
export const SELECT_ERROR_CLASS = `${SELECT_CLASS}-error`;
export const SLIDER_CLASS = 'shell-slider';
export const STAT_CLASS = 'shell-stat';
export const STEPPER_CLASS = 'shell-stepper';
export const TABLE_CLASS = 'shell-table';
export const TABS_CLASS = 'shell-tabs';
export const TAG_CLASS = 'shell-tag';
export const SECTION_CLASS = 'shell-text-article';
export const DISPLAY_CLASS = 'shell-text-display';
export const HEADING_CLASS = 'shell-text-heading';
export const LABEL_CLASS = 'shell-text-label';
export const LINK_CLASS = 'shell-text-link';
export const PARAGRAPH_CLASS = 'shell-text-paragraph';
export const TEXT_AREA_CLASS = 'shell-text-area';
export const TEXT_INPUT_CLASS = 'shell-text-input';
export const TIMELINE_CLASS = 'shell-timeline';
export const TOGGLE_CLASS = 'shell-toggle';
export const TOGGLE_BUTTON_CLASS = 'shell-toggle';
export const TOGGLE_SWITCH_CLASS = `${TOGGLE_CLASS}-switch`;
export const TOOLTIP_CLASS = 'shell-tooltip';
export const TREE_CLASS = 'shell-tree';
export const TREE_CHECKBOX_CLASS = `${TREE_CLASS}-checkbox-wrapper`;
export const TREE_CHECKBOX_CHECKED_CLASS = `${TREE_CHECKBOX_CLASS}-checked`;
export const TREE_CHECKBOX_UNCHECKED_CLASS = `${TREE_CHECKBOX_CLASS}-unchecked`;
export const TREE_CHECKBOX_INDETERMINATE_CLASS = `${TREE_CHECKBOX_CLASS}-indeterminate`;

export const ASC = 'ascend';
export const DESC = 'descend';

export const ALT_TEXT = 'Image';
