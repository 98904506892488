import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import Button from '../Button/Button';
import { modalConsts } from './Modal.consts';
import { ModalPadding, ModalSize, IModalProps } from './Modal.types';
import { commonAnimation, fadeIn, fadeOut, zoomIn, zoomOut } from './utils/styleUtils';

type ModalProps = Required<
  Pick<IModalProps, 'prefixCls' | 'bodyPadding' | 'size' | 'padding' | 'contentScrollable' | 'centered'>
> &
  Pick<IModalProps, 'borders'> & {
    bodyCentered: boolean;
    onlyBody: boolean;
  };

const getBodyPadding = (size: ModalSize, padding: ModalPadding, bodyPadding: boolean) => {
  if (!bodyPadding) {
    return '0';
  }

  return modalConsts[size].bodyPadding[padding];
};

const Modal = styled.div<ModalProps>(
  ({
    prefixCls,
    bodyPadding,
    size,
    padding,
    contentScrollable,
    theme,
    centered,
    bodyCentered,
    onlyBody,
    borders,
  }) => css`
    .${prefixCls} {
      position: relative;
      margin: ${centered ? 0 : '10vh auto'};

      &-wrap {
        position: fixed;
        overflow: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${Z_INDEX.MODAL};
        -webkit-overflow-scrolling: touch;
        outline: 0;

        ${centered &&
        css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      }

      &-mask {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: ${theme.background.mask};
        height: 100%;
        z-index: ${Z_INDEX.MODAL};
      }

      &-mask-hidden {
        display: none;
      }

      &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        min-height: 250px;
        border-radius: 6px;
        background-color: ${theme.background.raised};
        background-clip: padding-box;
        box-shadow: ${theme.elevation.level3};

        ${contentScrollable &&
        css`
          max-height: 80vh;

          .${prefixCls}-body {
            overflow-y: auto;
          }
        `}

        ${bodyCentered &&
        css`
          align-items: center;
          justify-content: center;
        `}
      }

      ${borders &&
      css`
        &-header {
          border-bottom: 1px solid ${theme.border.subtle};
        }
      `}

      &-title {
        display: flex;
      }

      &-body {
        flex: 1 1 auto;
        padding: ${getBodyPadding(size, padding, bodyPadding)};

        ${onlyBody &&
        css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      }

      &-close {
        display: none;
      }

      &-footer {
        ${borders &&
        css`
          border-top: 1px solid ${theme.border.subtle};
        `}
        text-align: right;
      }

      /* Animations */
      &-zoom-enter,
      &-zoom-appear {
        opacity: 0;
        ${css(commonAnimation)};
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      }

      &-zoom-leave {
        ${css(commonAnimation)};
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      }

      &-zoom-enter.${prefixCls}-zoom-enter-active, &-zoom-appear.${prefixCls}-zoom-appear-active {
        animation-name: ${zoomIn};
        animation-play-state: running;
      }

      &-zoom-leave.${prefixCls}-zoom-leave-active {
        animation-name: ${zoomOut};
        animation-play-state: running;
      }

      &-fade-enter,
      &-fade-appear {
        opacity: 0;
        ${css(commonAnimation)};
        animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      }

      &-fade-leave {
        ${css(commonAnimation)};
        animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      }

      &-fade-enter.${prefixCls}-fade-enter-active, &-fade-appear.${prefixCls}-fade-appear-active {
        animation-name: ${fadeIn};
        animation-play-state: running;
      }

      &-fade-leave.${prefixCls}-fade-leave-active {
        animation-name: ${fadeOut};
        animation-play-state: running;
      }
    }
  `,
);

const CloseButton = styled(Button)<Required<Pick<IModalProps, 'size' | 'padding'>>>`
  ${({ size, padding }) => css`
    position: absolute;
    ${modalConsts[size].closeButton.position[padding]};
  `}
`;

export { Modal, CloseButton };
