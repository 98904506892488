import styled, { css } from 'styled-components';

import Button from '../Button/Button';
import { textInputConsts, textInputWrapperConsts } from './TextInput.consts';
import { ITextInputProps, TextInputSize } from './TextInput.types';

type TextInputProps = Pick<ITextInputProps, 'disabled'> & {
  inputSize: TextInputSize;
};

type TextInputAffixProps = Pick<ITextInputProps, 'size' | 'disabled'> & {
  transparent?: boolean;
};

const TextInput = styled.input<TextInputProps>`
  ${({ disabled, inputSize, theme }) => css`
    border: none;
    width: 100%;
    color: ${theme.text.onSurface.strong};
    background-color: ${theme.background.surface};
    transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: ${textInputConsts.padding[inputSize]};

    ${(inputSize === 'small' || inputSize === 'medium') && theme.paragraph.small.normal};
    ${inputSize === 'large' && theme.paragraph.medium.normal};

    &::placeholder {
      color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.subtle};
      font-style: italic;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
      border-color: ${theme.border.subtle};
      cursor: not-allowed;
    }
  `}
`;

const Affix = styled.div<TextInputAffixProps>`
  ${({ size = 'medium', transparent = false, disabled, theme }) => css`
    ${theme.label[size === 'small' ? 'medium' : size].bold};
    display: flex;
    align-items: center;
    padding: 0 ${textInputWrapperConsts.iconMargin[size]};
    background: ${transparent ? 'transparent' : theme.background.section};
    color: ${theme.text.onSurface.subtle};

    ${disabled &&
    css`
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
    `}

    svg {
      width: ${textInputWrapperConsts.iconSize[size]};
      height: ${textInputWrapperConsts.iconSize[size]};
    }
  `}
`;

export const ClearButton = styled(Button)`
  margin-top: -1px;
  margin-bottom: -1px;
`;

export { TextInput, Affix };
