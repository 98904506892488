type TextInputWrapperConstsProps = {
  iconSize: {
    small: string;
    medium: string;
    large: string;
  };
  iconMargin: {
    small: string;
    medium: string;
    large: string;
  };
};

export const textInputConsts = {
  padding: {
    small: '4px 10px',
    medium: '8px 12px',
    large: '11px 16px',
  },
};

export const textInputWrapperConsts: TextInputWrapperConstsProps = {
  iconSize: {
    small: '16px',
    medium: '20px',
    large: '24px',
  },
  iconMargin: {
    small: '8px',
    medium: '12px',
    large: '16px',
  },
};
