import React, { ReactElement, ReactNode, Children, isValidElement } from 'react';

import classNames from 'classnames';

import { STEPPER_CLASS } from '../../utils/constants';
import Step from './components/Step/Step';
import * as Styled from './Stepper.styles';
import { IStepperProps } from './Stepper.types';
import { updateProps } from './utils/stepperUtils';
import HorizontalStep from './components/HorizontalStep/HorizontalStep';
import VerticalStep from './components/VerticalStep/VerticalStep';

const Stepper = ({
  className,
  size = 'medium',
  mode = 'horizontal',
  alignment = 'right',
  children = [],
  ariaLabel = 'stepper',
  current,
  type = 'base',
  finishedSteps,
  keepFinishedSteps = false,
  ...rest
}: IStepperProps): ReactElement => {
  const StepComponent = mode === 'horizontal' ? HorizontalStep : VerticalStep;

  const steps = Children.map(children, (child: ReactNode, index: number): ReactElement | null => {
    if (isValidElement(child)) {
      return (
        <StepComponent
          {...updateProps(
            child.props,
            size,
            mode,
            alignment,
            index,
            current,
            children?.length,
            type,
            keepFinishedSteps,
            finishedSteps,
          )}
        />
      );
    }

    return null;
  });

  return (
    <Styled.Stepper
      aria-label={rest['aria-label'] || ariaLabel}
      className={classNames(STEPPER_CLASS, className)}
      mode={mode}
      alignment={alignment}
      {...rest}
    >
      {steps}
    </Styled.Stepper>
  );
};

Stepper.Step = Step;

export default Stepper;
