import styled, { css } from 'styled-components';

import { Transient } from '../../../../types/types';
import { textConsts } from '../../Text.consts';
import { ILabelProps } from './Label.types';

const Label = styled.span<Required<Pick<ILabelProps, 'size' | 'gutter' | 'bold'>> & Transient<ILabelProps, 'color'>>`
  ${({ size, $color, gutter, bold, theme }) => css`
    ${bold
      ? css`
          ${size === 'xsmall' && theme.label.extraSmall.bold};
          ${size === 'small' && theme.label.small.bold};
          ${size === 'medium' && theme.label.medium.bold};
          ${size === 'large' && theme.label.large.bold};
          ${size === 'xlarge' && theme.label.extraLarge.demi};
        `
      : css`
          ${size === 'xsmall' && theme.label.extraSmall.normal};
          ${size === 'small' && theme.label.small.normal};
          ${size === 'medium' && theme.label.medium.normal};
          ${size === 'large' && theme.label.large.normal};
          ${size === 'xlarge' && theme.label.extraLarge.normal};
        `}

    margin-top: 0;
    margin-bottom: 0;
    color: ${$color === 'normal' ? theme.text.onSurface.strong : theme.text.onSurface.subtle};

    ${gutter &&
    css`
      margin-bottom: ${textConsts.gutter.label};
    `}
  `}
`;

export { Label };
