import React, { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { ALERT_CLASS, ICON_CLASS, NOOP } from '../../utils/constants';
import Button from '../Button/Button';
import { AlertCircle, CheckCircle, Cross, CrossCircle, InfoCircle, QuestionMarkCircle } from '../Icon/components';
import * as Styled from './Alert.styles';
import { AlertSentiments, IAlertProps } from './Alert.types';

const getAlertStateIcon = (sentiment: AlertSentiments): ReactElement => {
  const icons: { [key: string]: JSX.Element } = {
    neutral: <QuestionMarkCircle />,
    positive: <CheckCircle />,
    negative: <CrossCircle />,
    warning: <AlertCircle />,
    information: <InfoCircle />,
  };

  return icons[sentiment];
};

const Alert = forwardRef<HTMLDivElement, IAlertProps>(
  (
    {
      className,
      title,
      children,
      sentiment,
      state,
      icon,
      iconVisibility = true,
      float = false,
      dismissible = false,
      onDismissClick = NOOP,
      size = 'large',
      buttonsPosition = 'bottom',
      actions,
      dismissButtonTitle = 'Dismiss',
      ...rest
    },
    ref,
  ): ReactElement => {
    const sentimentProp = sentiment || state || 'neutral';
    const iconComponent = icon || getAlertStateIcon(sentimentProp);

    return (
      <Styled.Alert
        className={classNames(ALERT_CLASS, className)}
        sentiment={sentimentProp}
        float={float}
        size={size}
        ref={ref}
        {...rest}
      >
        {iconVisibility && <Styled.IconContainer sentiment={sentimentProp}>{iconComponent}</Styled.IconContainer>}
        <Styled.AlertContentContainer buttonsPosition={buttonsPosition}>
          <Styled.AlertContent>
            {title && <Styled.Title size={size}>{title}</Styled.Title>}
            {children}
          </Styled.AlertContent>
          {actions?.length && (
            <Styled.ButtonsContainer buttonsPosition={buttonsPosition}>
              {actions.map(({ label, action, props }) => (
                <Button key={label} onClick={action} {...props} size="small" variant="outlined">
                  {label}
                </Button>
              ))}
            </Styled.ButtonsContainer>
          )}
        </Styled.AlertContentContainer>
        {dismissible && (
          <Styled.DismissContainer>
            <Styled.DismissButton
              title={dismissButtonTitle}
              onClick={onDismissClick}
              variant="transparent"
              iconOnly
              size="small"
              icon={<Cross className={ICON_CLASS} />}
            />
          </Styled.DismissContainer>
        )}
      </Styled.Alert>
    );
  },
);

export default Alert;
