import React, { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import { ACCORDION_CLASS } from '../../utils/constants';
import { accordionConsts, AccordionIcon } from './Accordion.consts';
import * as Styled from './Accordion.styles';
import { IAccordionProps } from './Accordion.types';

const Accordion = ({
  className,
  size = 'medium',
  children,
  bodyPadding = true,
  fullWidth = true,
  nested = false,
  iconSet = 'plus',
  ...rest
}: IAccordionProps): ReactElement => {
  const handleExpandIcon = ({ isActive }: object & { isActive?: boolean }): ReactNode => {
    const Icon = AccordionIcon[iconSet][isActive ? 'active' : 'inactive'];

    return <Icon width={accordionConsts[size].iconSize} height={accordionConsts[size].iconSize} />;
  };

  return (
    <Styled.Accordion
      className={classNames(ACCORDION_CLASS, className)}
      prefixCls={ACCORDION_CLASS}
      size={size}
      expandIcon={handleExpandIcon}
      bodyPadding={bodyPadding}
      fullWidth={fullWidth}
      nested={nested}
      {...rest}
    >
      {children}
    </Styled.Accordion>
  );
};

export default Accordion;
