import React, { ReactElement } from 'react';

import { Check, Cross } from '../../../Icon/components';
import * as Styled from './StepDot.styles';
import { IStepDotProps } from './StepDot.types';
import Heading from '../../../Text/components/Heading/Heading';
import { getTitleLevel } from '../../utils/stepUtils';

const StepDot = ({
  size,
  mode,
  alignment,
  type = 'base',
  inProgress = false,
  order,
  waiting,
  invalid,
  isFinished,
}: IStepDotProps): ReactElement => {
  const StepDotContent = () => (
    <>
      {!invalid && !isFinished && <Heading level={getTitleLevel(size)}>{order}</Heading>}
      {invalid && <Cross />}
      {isFinished && <Check />}
    </>
  );

  return (
    <Styled.StepDot
      size={size}
      mode={mode}
      alignment={alignment}
      type={type}
      inProgress={inProgress}
      isFinished={isFinished}
      waiting={waiting}
      invalid={invalid}
    >
      {type !== 'dot' && <StepDotContent />}
    </Styled.StepDot>
  );
};

export default StepDot;
