import styled, { css } from 'styled-components';

import { StepType } from '../../Stepper.types';
import { getTextColor } from '../../utils/styleUtils';
import Heading from '../../../Text/components/Heading/Heading';
import Text from '../../../Text/components/Text/Text';
import { IHeadingProps } from '../../../Text/components/Heading/Heading.types';
import { ITextProps } from '../../../Text/components/Text/Text.types';

type TitleProps = IHeadingProps & {
  invalid: boolean;
  waiting: boolean;
};

type DescriptionProps = ITextProps & {
  stepType?: StepType;
};

const Title = styled(Heading)<TitleProps>`
  ${({ waiting, invalid, theme }) => css`
    color: ${getTextColor(theme, invalid, waiting)};
  `}
`;

const Description = styled(Text)<DescriptionProps>`
  ${({ theme }) => css`
    display: block;
    color: ${theme.text.onSurface.subtle};
  `}
`;

export { Title, Description };
