import { IStepProps } from '../components/Step/Step.types';
import { StepType, StepperSize, StepperMode, StepperAlignment } from '../Stepper.types';

export const updateProps = (
  props: IStepProps,
  size: StepperSize,
  mode: StepperMode,
  alignment: StepperAlignment,
  index: number,
  current: number,
  childrenLength: number,
  type: StepType,
  keepFinishedSteps: boolean,
  finishedSteps?: number[],
): IStepProps => ({
  ...props,
  ...{
    key: props.key || props.title,
    size,
    mode,
    alignment,
    type,
    finish: keepFinishedSteps ? finishedSteps?.includes(index) : index < current,
    isFinished: keepFinishedSteps ? finishedSteps?.includes(index) : index < current,
    inProgress: index === current,
    waiting: index > current,
    last: childrenLength === index + 1,
  },
});
