import { forwardRef } from 'react';

import CircleProgressBar from './components/CircleProgressBar/CircleProgressBar';
import LinearProgressBar from './components/LinearProgressBar/LinearProgressBar';
import { IProgressBarProps } from './ProgressBar.types';

const normalizeValue = (value: number): number => {
  if (value > 100) {
    return 100;
  }
  if (value < 0) {
    return 0;
  }

  return value;
};

const ProgressBar = forwardRef<HTMLDivElement, IProgressBarProps>(
  ({ className, type = 'line', value = 0, sentiment, state, size = 'medium', label = false }, ref) => {
    const valueNormalized = normalizeValue(value);

    if (type === 'line') {
      return (
        <LinearProgressBar
          className={className}
          value={valueNormalized}
          sentiment={sentiment || state || 'default'}
          size={size}
          label={label}
          ref={ref}
        />
      );
    }

    return (
      <CircleProgressBar
        className={className}
        value={valueNormalized}
        sentiment={sentiment || state || 'default'}
        size={size}
        label={label}
        ref={ref}
      />
    );
  },
);

export default ProgressBar;
