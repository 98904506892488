import classNames from 'classnames';

import { ACCORDION_PANEL_CLASS } from '../../../../utils/constants';
import HeaderAccordion from '../HeaderAccordion/HeaderAccordion';
import * as Styled from './AccordionPanel.styles';
import { IAccordionPanelProps } from './AccordionPanel.types';

const AccordionPanel = ({
  className,
  children,
  border = true,
  keyId,
  header,
  label,
  ...rest
}: IAccordionPanelProps) => (
  <Styled.AccordionPanel
    className={classNames(ACCORDION_PANEL_CLASS, className)}
    $border={border}
    key={keyId}
    header={<HeaderAccordion header={header} label={label} />}
    {...rest}
    prefixCls={ACCORDION_PANEL_CLASS}
  >
    {children}
  </Styled.AccordionPanel>
);

export default AccordionPanel;
