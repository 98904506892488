import styled, { css } from 'styled-components';

import { Transient } from '../../../../types/types';
import { textConsts } from '../../Text.consts';
import { TextSize, ITextProps } from './Text.types';

const textFont = (size: string, weight: 'normal' | 'bold') => css`
  ${({ theme }) => css`
    ${size === 'small' && theme.paragraph.small[weight]}
    ${size === 'medium' && theme.paragraph.medium[weight]}
    ${size === 'large' && theme.paragraph.large[weight]}
    ${size === 'xlarge' && theme.paragraph.extraLarge[weight]}
  `}
`;

const Text = styled.p<Transient<ITextProps & { size: TextSize }, 'color'>>`
  ${({ size, gutter, bold, $color, theme }) => css`
    ${textFont(size, bold ? 'bold' : 'normal')}
    margin-top: 0;
    margin-bottom: 0;
    color: ${$color === 'normal' ? theme.text.onSurface.strong : theme.text.onSurface.subtle};

    ${gutter &&
    css`
      margin-bottom: ${textConsts.gutter.p};
    `}

    strong {
      ${textFont(size, 'bold')}
    }
  `}
`;

export { Text };
