import styled, { css } from 'styled-components';

import { Transient } from '../../types/types';
import { GridAreas, ICellProps, IGridProps } from './Grid.types';

const autoRows = ({ minRowHeight = '32px' }): string => `minmax(${minRowHeight}, auto)`;
const getFr = (value: string | number): string => String(typeof value === 'number' ? `repeat(${value}, 1fr)` : value);
const gridGap = ({ gap = '12px' }): string => gap;
const formatAreas = (areas: GridAreas): string => areas.map((area) => `"${area}"`).join(' ');

const Grid = styled.div<Transient<IGridProps, 'height' | 'width'>>`
  display: grid;
  grid-auto-rows: ${autoRows};
  grid-gap: ${gridGap};

  ${({
    $width,
    $height,
    flow,
    rows,
    columns,
    columnGap,
    rowGap,
    areas,
    justifyContent,
    alignContent,
    justifyItems,
    alignItems,
  }) => css`
    width: ${$width || '100%'};
    height: ${$height || 'auto'};
    grid-auto-flow: ${flow || 'row'};
    grid-template-columns: ${columns && getFr(columns)};
    ${rows && `grid-template-rows: ${getFr(rows)}`};
    ${columnGap && `column-gap: ${columnGap}`};
    ${rowGap && `row-gap: ${rowGap}`};
    ${areas && `grid-template-areas: ${formatAreas(areas)}`};
    ${justifyContent && `justify-content: ${justifyContent}`};
    ${alignContent && `align-content: ${alignContent}`};
    ${justifyItems && `justify-items: ${justifyItems}`};
    ${alignItems && `align-items: ${alignItems}`};
  `}
`;

const Cell = styled.div<Transient<ICellProps, 'height' | 'width'>>`
  height: 100%;
  min-width: 0;
  box-sizing: border-box;

  ${({ $width, $height, area, columnStart, rowStart, centerContent, columnEnd, rowEnd }) => css`
    /* Avoids breaking change as column-end and row-end previously used width and height */
    ${!columnEnd && $width && `grid-column-end: span ${$width}`};
    ${!rowEnd && $height && `grid-row-end: span ${rowEnd}`};

    ${columnStart && `grid-column-start: ${columnStart}`};
    ${columnEnd && `grid-column-end: ${columnEnd}`};
    ${rowStart && `grid-row-start: ${rowStart}`};
    ${rowEnd && `grid-row-end: ${rowEnd}`};
    ${area && `grid-area: ${area}`};
    ${centerContent &&
    `
      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;
    `};
  `}
`;

export { Grid, Cell };
