import styled, { css } from 'styled-components';

import { ProgressBarConstsColors, progressBarConsts } from '../../ProgressBarConsts';
import { ProgressBarSize, ProgressBarSentiment } from '../../ProgressBar.types';
import { getStrokeDashArray, getStrokeDashoffset } from '../../../../utils/circleSvgUtils';

type ProgressBarProps = {
  sentiment: ProgressBarSentiment;
  value: number;
  r: number;
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ProgressBar = styled.svg<{ size: ProgressBarSize }>`
  ${({ size }) => css`
    width: ${`${progressBarConsts[size].viewBoxSize}px`};
    height: ${`${progressBarConsts[size].viewBoxSize}px`};
    overflow: inherit;
    transform: rotate(-90deg);

    & > * {
      stroke-width: ${progressBarConsts[size].strokeWidth}px;
    }
  `}
`;

const Circle = styled.circle`
  fill: transparent;
`;

const Background = styled(Circle)`
  ${({ theme }) => css`
    stroke: ${theme.border.subtle};
  `}
`;

const Progress = styled(Circle)<ProgressBarProps>`
  ${({ theme, sentiment, value, r }) => css`
    stroke: ${ProgressBarConstsColors(theme).color[sentiment]};
    stroke-linecap: round;
    stroke-dasharray: ${`${getStrokeDashArray(r)}`};
    stroke-dashoffset: ${getStrokeDashoffset(r, value)};
    transition: stroke-dashoffset 1s;
  `}
`;

const Label = styled.span<{ size: ProgressBarSize }>`
  ${({ size, theme }) => css`
    ${size === 'small' && theme.heading[4].normal};
    ${size === 'medium' && theme.heading[3].normal};
    ${size === 'large' && theme.heading[2].normal};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export { Container, ProgressBar, Background, Progress, Label };
