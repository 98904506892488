import styled, { css } from 'styled-components';

import { radioButtonConsts } from './RadioButton.consts';
import { IRadioButtonProps } from './RadioButton.types';

type RadioButtonProps = Required<
  Pick<IRadioButtonProps, 'size' | 'disabled'> & {
    $emphasis: boolean;
  }
> & {
  checked?: boolean;
};

type FakeRadioProps = Required<Pick<IRadioButtonProps, 'size' | 'invalid'>>;

type RadioProps = Omit<IRadioButtonProps, 'emphasis' & 'invalid'> & {
  $emphasis: boolean;
  $invalid: boolean;
};

const FakeRadio = styled.div<FakeRadioProps>`
  display: inline-block;
  border-radius: 100%;
  position: relative;

  ${({ size, invalid, theme }) => css`
    flex-shrink: 0;
    height: ${radioButtonConsts[size].size}px;
    width: ${radioButtonConsts[size].size}px;
    background: ${theme.background.surface};
    border: 2px solid ${invalid ? theme.system.strong.negative : theme.border.medium};
  `}
`;

const Radio = styled.input<RadioProps>`
  position: absolute;
  left: -9999px;

  ${({ theme, $emphasis, $invalid }) => css`
    &:checked ~ ${FakeRadio} {
      ${$invalid
        ? css`
            background-color: ${theme.system.strong.negative};
            border-color: ${theme.system.strong.negative};
          `
        : css`
            background-color: ${$emphasis ? theme.focus.strong : theme.text.onPrimary.strong};
            border-color: ${$emphasis ? theme.focus.strong : theme.text.onPrimary.strong};
          `};

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.text.onFocus.strong};
        border-radius: 100%;
      }
    }

    &:active:not(:disabled) ~ ${FakeRadio} {
      border-color: ${$emphasis ? theme.focus.strong : theme.text.onPrimary.strong};

      svg {
        margin: auto;

        path {
          fill: ${$emphasis ? theme.focus.strong : theme.text.onPrimary.strong};
        }
      }
    }

    &:focus ~ ${FakeRadio} {
      box-shadow: 0 0 0 4px ${theme.focus.medium};
      outline: 0;
    }

    &:disabled ~ ${FakeRadio} {
      background-color: ${theme.background.inactive};
      border-color: ${theme.border.subtle};
    }

    &:disabled:checked ~ ${FakeRadio} {
      background-color: ${$emphasis ? theme.focus.subtle : theme.text.onPrimary.inactive};
      border-color: ${$emphasis ? theme.focus.subtle : 'transparent'};
    }
  `}
`;

const RadioButton = styled.label<RadioButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;

  ${({ disabled, size, checked, $emphasis, theme }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    span {
      ${size === 'small' && theme.label.small.bold};
      ${size === 'medium' && theme.label.medium.bold};
      ${size === 'large' && theme.label.large.bold};
      color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.strong};
      margin-left: ${radioButtonConsts[size].spacing}px;
    }

    ${!disabled &&
    css`
      &:hover {
        & div::after {
          content: '';
          width: ${radioButtonConsts[size].innerCircleSize}px;
          height: ${radioButtonConsts[size].innerCircleSize}px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: ${checked ? 0.5 : 1};
          background-color: ${checked ? theme.background.surface : theme.text.onSurface.subtle} !important;
          border-radius: 100%;
        }
      }
    `}

    ${Radio} {
      &:checked ~ ${FakeRadio} {
        &::after {
          width: ${radioButtonConsts[size].innerCircleSize}px;
          height: ${radioButtonConsts[size].innerCircleSize}px;
        }
      }

      &:active:not(:disabled) ~ ${FakeRadio} {
        &::after {
          background-color: ${$emphasis ? theme.focus.strong : theme.text.onPrimary.strong} !important;
        }
      }
    }
  `}
`;

export { Radio, FakeRadio, RadioButton };
