import styled, { css } from 'styled-components';

import { Transient } from '../../../../types/types';
import { textConsts } from '../../Text.consts';
import { HeadingLevel, IHeadingProps } from './Heading.types';

const headingFontNormal = (l: number) => css`
  ${({ theme }) => css`
    ${l === 1 && theme.heading[1].normal}
    ${l === 2 && theme.heading[2].normal}
    ${l === 3 && theme.heading[3].normal}
    ${l === 4 && theme.heading[4].normal}
    ${(l === 5 || l === 6) && theme.heading[5].normal}
  `}
`;

const headingFontBold = (l: number) => css`
  ${({ theme }) => css`
    ${l === 1 && theme.heading[1].demi}
    ${l === 2 && theme.heading[2].demi}
    ${l === 3 && theme.heading[3].demi}
    ${l === 4 && theme.heading[4].bold}
    ${(l === 5 || l === 6) && theme.heading[5].bold}
  `}
`;

const Heading = styled.h1<Transient<IHeadingProps, 'color'> & { as: string }>`
  ${({ level, gutter, bold, $color, theme, type, as }) => {
    const l: HeadingLevel =
      level || ((['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(type || as || 'h1') + 1) as HeadingLevel) || 1;

    return css`
      ${bold ? headingFontBold(l) : headingFontNormal(l)}
      margin: unset;
      color: ${$color === 'normal' ? theme.text.onSurface.strong : theme.text.onSurface.subtle};

      ${gutter &&
      l === 1 &&
      css`
        margin-bottom: ${textConsts.gutter.h1};
      `}

      ${gutter &&
      (l === 2 || l === 3 || l === 4) &&
      css`
        margin-bottom: ${textConsts.gutter.h2};
      `}

      ${gutter &&
      (l === 5 || l === 6) &&
      css`
        margin-bottom: ${textConsts.gutter.h5};
      `}

      strong {
        ${headingFontBold(l)}
      }
    `;
  }}
`;

export { Heading };
