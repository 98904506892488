import React, { ReactElement } from 'react';

import StepDot from '../StepDot/StepDot';
import StepWrapper from '../StepWrapper/StepWrapper';
import * as Styled from './HorizontalStep.styles';
import { IStepProps } from '../Step/Step.types';
import { getTitleLevel } from '../../utils/stepUtils';

const HorizontalStep = ({
  size = 'medium',
  mode = 'horizontal',
  alignment = 'right',
  type = 'base',
  order,
  title,
  description,
  inProgress = false,
  waiting = false,
  invalid = false,
  isFinished = false,
  last = false,
  width,
  ...rest
}: IStepProps): ReactElement => (
  <StepWrapper
    alignment={alignment}
    type={type}
    last={last}
    aria-current={inProgress ? 'step' : undefined}
    $width={width}
    {...rest}
  >
    <Styled.Row alignment={alignment}>
      <StepDot
        size={size}
        mode={mode}
        alignment={alignment}
        type={type}
        inProgress={inProgress}
        order={order}
        waiting={waiting}
        invalid={invalid}
        isFinished={isFinished}
      />
      <Styled.HorizontalTitle level={getTitleLevel(size)} waiting={waiting} invalid={invalid}>
        {title}
      </Styled.HorizontalTitle>
      {!last && <Styled.Line size={size} isFinished={isFinished} invalid={invalid} waiting={waiting} />}
    </Styled.Row>
    <Styled.HorizontalDescription size={size} alignment={alignment} stepType={type}>
      {description}
    </Styled.HorizontalDescription>
  </StepWrapper>
);

export default HorizontalStep;
