import { ComponentPropsWithRef, ForwardedRef, forwardRef, useMemo } from 'react';

import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { ICheckboxProps } from '../Checkbox/Checkbox.types';
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ChevronDown,
  ChevronRight,
  ChevronUpDown,
  Cross,
  DragIos,
  Filter,
} from '../Icon/components';
import { TableContext, useTable } from './hooks/useTable';
import * as Styled from './Table.styles';
import { ITableExpander, ITablePin, ITableProps, ITableSorterProps, TableSize } from './Table.types';

export const getSize = (size: TableSize) => (size === 'xsmall' ? 'small' : size);

export const TableSorter = forwardRef(
  ({ sortDirection, ...props }: ITableSorterProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size } = useTable();

    return (
      <Styled.TableSorter
        iconOnly
        icon={
          <>
            {sortDirection === 'asc' && <ArrowDown data-testid="asc-sorter" width={16} height={16} />}
            {sortDirection === 'desc' && <ArrowUp data-testid="desc-sorter" width={16} height={16} />}
            {!sortDirection && <ChevronUpDown data-testid="no-sorter" width={16} height={16} />}
          </>
        }
        size={getSize(size)}
        variant="transparent"
        ref={ref}
        {...props}
      />
    );
  },
);

export const TableFilter = forwardRef(
  ({ ...props }: ComponentPropsWithRef<'button'>, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size } = useTable();

    return (
      <Styled.TableFilter iconOnly icon={<Filter />} size={getSize(size)} variant="transparent" ref={ref} {...props} />
    );
  },
);

export const TablePin = forwardRef(
  ({ pinIconDirection, ...props }: ITablePin, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size } = useTable();

    return (
      <Button
        iconOnly
        icon={
          // eslint-disable-next-line no-nested-ternary
          pinIconDirection === 'left' ? (
            <ArrowLeft data-testid="pin-arrow-left" />
          ) : pinIconDirection === 'right' ? (
            <ArrowRight data-testid="pin-arrow-right" />
          ) : (
            <Cross data-testid="pin-cross" />
          )
        }
        size={getSize(size)}
        variant="transparent"
        ref={ref}
        {...props}
      />
    );
  },
);

export const TableExpander = forwardRef(
  ({ expanded, ...props }: ITableExpander, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size } = useTable();

    return (
      <Button
        iconOnly
        icon={
          expanded ? <ChevronDown data-testid="expander-expanded" /> : <ChevronRight data-testid="expander-collapsed" />
        }
        size={getSize(size)}
        variant="transparent"
        ref={ref}
        {...props}
      />
    );
  },
);

export const TableDrag = forwardRef(
  ({ ...props }: ComponentPropsWithRef<'button'>, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size } = useTable();

    return (
      <Styled.TableDrag
        ref={ref}
        variant="transparent"
        iconOnly
        icon={<DragIos data-testid="drag" />}
        size={getSize(size)}
        {...props}
      />
    );
  },
);

export const TableCheckbox = forwardRef((props: ICheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { size } = useTable();

  return <Checkbox label="" size={getSize(size)} ref={ref} {...props} />;
});

export const Table = forwardRef(({ size, align, ...props }: ITableProps, ref: ForwardedRef<HTMLTableElement>) => {
  const value = useMemo(
    () => ({
      size,
      align,
    }),
    [size, align],
  );

  return (
    <TableContext.Provider value={value}>
      <Styled.Table ref={ref} size={size} align={align} {...props} />
    </TableContext.Provider>
  );
});
