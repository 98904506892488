import { forwardRef } from 'react';

import { useTooltip } from '@react-aria/tooltip';
import classNames from 'classnames';

import Styled from './Overlay.styles';
import { IOverlayProps } from './Overlay.types';

const Overlay = forwardRef<HTMLElement, IOverlayProps>(
  ({ children, arrowRef, arrowProps, triggerState, className, ...props }, ref) => {
    const { tooltipProps } = useTooltip(props, triggerState);
    const { placement, overlayProps } = props;

    return (
      <Styled.Overlay {...tooltipProps} {...overlayProps} className={classNames(className, placement)} ref={ref}>
        {children || null}
        <span className="tip" {...arrowProps} ref={arrowRef} />
      </Styled.Overlay>
    );
  },
);

export { Overlay };
